<template>
  <validation-observer
      ref="editOrganizationForm"
      #default="{invalid}">
    <b-form @submit.prevent>
      <b-row align-h="start">

        <b-col cols="12">
          <b-form-group
              :label="$t('organization.name')"
              label-for="fh-doc-name">
            <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                rules="required">
              <b-form-input
                  v-model="name"
                  id="fh-doc-name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('organization.name')"/>
              <small v-if="errors.length > 0" class="text-danger">
                {{ $t('required') }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="end" class="px-1">
            <b-button
                @click="save"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                :disabled="invalid"
                variant="primary">
              {{ $t('buttons.save') }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import { required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useWorkspacesList from "@/views/Workspace/List/useWorkspacesList";
import {PEventBus, OrganizationEventTypes} from "@/services/PEventBus";
import i18n from "@/libs/i18n";

export default {
  name: "index",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    organization: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      organizationId: this.organization.organizationId,
      name: this.organization.name,
      // validation rules
      required,
    }
  },
  computed: {
    validation() {
      return true
    },
    validationOccupation() {
      return true
    },
  },
  mounted() {
    PEventBus.$on(OrganizationEventTypes.ORGANIZATION_UPDATED, (evt) => this.showSuccessToast())
    PEventBus.$on(OrganizationEventTypes.ORGANIZATION_UPDATE_FAILED, () => this.showErrorToast())
  },
  beforeDestroy() {
    PEventBus.$off(OrganizationEventTypes.ORGANIZATION_UPDATED, (evt) => this.showSuccessToast())
    PEventBus.$off(OrganizationEventTypes.ORGANIZATION_UPDATE_FAILED, () => this.showErrorToast())
  },
  methods: {
    save() {
      this.$refs.editOrganizationForm.validate()
          .then(success => {
            if (success) {
              const payload = {
                organizationId: this.organizationId,
                request: {
                  name: this.name
                }
              }
              this.$store.dispatch('organizations/update', payload)
                  .catch(err => {})
            }
          })
          // .then(() => this.fetchOrganizations());
    },
    fetchOrganizations() {
      const {
        fetchMyWorkspaces
      } = useWorkspacesList()

      return fetchMyWorkspaces()
    },
    showSuccessToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.update'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, {position: 'top-center'})
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.failedOnUpdate'),
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, {position: 'top-center'})
    },
  }
}
</script>

<style scoped>

</style>
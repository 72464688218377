<template>
  <div>
    <loading v-if="loading"></loading>
    <div v-show="!loading">
      <b-modal scrollable
               ref="create-organization-modal"
               hide-footer
               no-close-on-backdrop
               :title="$t('organization.add')">
        <create-organization @reload="reloadOrganizations"/>
      </b-modal>
      <b-modal scrollable
               ref="edit-organization-modal"
               hide-footer
               no-close-on-backdrop
               :title="$t('organization.edit')">
        <edit-organization :organization="selectedOrganization"/>
      </b-modal>
      <b-row>
        <b-col>
          <h5 class="section-label mb-1">
            {{ $t('organization.myWorkspaces')}}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" md="6" sm="12" class="p-50">

          <b-card border-variant="gray"
                  style="padding:0; min-height:152px;"
                  @click="addOrganization"
                  class="cursor-pointer workspace-card justify-content-around">
            <div class="workspace-create-card"></div>
            <h4 class="add-new-card"><strong>{{ $t('organization.createNew') }}</strong></h4>
          </b-card>
        </b-col>
        <b-col v-for="(workspace,ix) in myWorkspaces"
               :key="ix"
               class="p-50"
               lg="3" md="6" sm="12">
          <b-link>
            <b-card border-variant="gray"
                    bg-variant="transparent"
                    class="workspace-card cursor-pointer">
              <b-card-title @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '1'}})">
                {{workspace.name}}
              </b-card-title>

              <b-card-sub-title @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '1'}})">
                {{workspace.code}}
              </b-card-sub-title>

              <div class="flex justify-content-end align-items-end align-bottom align-content-end pt-2">
                <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                          @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '2'}})"
                          v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                          v-b-tooltip.hover.top="$t('organization.groupsAndUsers')"
                          variant="flat-primary"
                          class="rounded-circle mr-50">
                  <div class="icon-wrapper-large">
                    <feather-icon icon="UsersIcon" size="13"/>
                  </div>
                </b-button>
                <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                          @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '3'}})"
                          v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                          v-b-tooltip.hover.top="$t('organization.invitedOnes')"
                          variant="flat-primary"
                          class="rounded-circle mr-50">
                  <div class="icon-wrapper-large">
                    <feather-icon icon="SendIcon" size="13"/>
                  </div>
                </b-button>
                <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                          @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '6'}})"
                          v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                          v-b-tooltip.hover.top="$t('organization.settings')"
                          variant="flat-primary"
                          class="rounded-circle mr-50">
                  <div class="icon-wrapper-large">
                    <feather-icon icon="SettingsIcon" size="13"/>
                  </div>
                </b-button>
              </div>
            </b-card>
          </b-link>
        </b-col>
      </b-row>

      <div v-if="invitedWorkspaces.length > 0">
        <b-row>
          <b-col>
            <h5 class="section-label mb-1">
              {{ $t('organization.invitedWorkspaces')}}
            </h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-for="(workspace,ix) in invitedWorkspaces" :key="ix" class="p-50" lg="3" md="6" sm="12">
            <b-link>
              <b-card border-variant="gray"
                      bg-variant="transparent"
                      class="workspace-card cursor-pointer">
                <b-card-title @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '1'}})">
                  {{workspace.name}}
                </b-card-title>

                <b-card-sub-title @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '1'}})">
                  {{workspace.code}}
                </b-card-sub-title>

                <div class="flex justify-content-end align-items-end align-bottom align-content-end pt-2">
                  <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                            @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '1'}})"
                            v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                            v-b-tooltip.hover.top="$t('organization.groupsAndUsers')"
                            variant="flat-primary"
                            class="rounded-circle mr-50">
                    <div class="icon-wrapper-large">
                      <feather-icon icon="UsersIcon" size="13"/>
                    </div>
                  </b-button>
                  <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                            @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '3'}})"
                            v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                            v-b-tooltip.hover.top="$t('organization.invitedOnes')"
                            variant="flat-primary"
                            class="rounded-circle mr-50">
                    <div class="icon-wrapper-large">
                      <feather-icon icon="SendIcon" size="13"/>
                    </div>
                  </b-button>
                  <b-button style=" background-color: rgba(3, 165, 170, 0.12)"
                            @click="$router.push({ name: 'workspace-detail', params: { id:  workspace.organizationId, tab: '6'}})"
                            v-ripple.400="'rgba(3, 165, 170, 0.12)'"
                            v-b-tooltip.hover.top="$t('organization.settings')"
                            variant="flat-primary"
                            class="rounded-circle mr-50">
                    <div class="icon-wrapper-large">
                      <feather-icon icon="SettingsIcon" size="13"/>
                    </div>
                  </b-button>
                </div>
              </b-card>
            </b-link>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BCard,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BLink,
  BModal,
  BRow,
  VBTooltip
} from "bootstrap-vue";
import {avatarText} from "@core/utils/filter";
import useWorkspacesList from "@/views/Workspace/List/useWorkspacesList";
import Loading from '../../components/Loading/Loading'
import CreateOrganization from '../Create/index';
import EditOrganization from '../Edit/index';
import {mapState} from "vuex";
import {PEventBus, OrganizationEventTypes} from "@/services/PEventBus";

export default {
  name: "OrganizationList",
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    Loading,
    BCol,
    BCard,
    BButton,
    BModal,
    BLink,
    BCardTitle,
    BCardSubTitle,
    CreateOrganization,
    EditOrganization,
  },
  computed: {
    ...mapState({
      loading: state => state.workspaces.loading,
      myWorkspaces: state => state.workspaces.myWorkspaces,
      invitedWorkspaces: state => state.workspaces.invitedWorkspaces
    }),
  },
  data() {
    return {
      selectedOrganization: null
    }
  },
  methods: {
    addOrganization() {
      this.$refs['create-organization-modal'].show()
    },
    reloadOrganizations() {
      this.$refs['create-organization-modal'].hide()
      this.$refs['edit-organization-modal'].hide()
      this.fetchMyWorkspaces("organizations", (val) => console.log("reloadOrganizations"))
      this.refetchData()
    },
  },
  mounted() {
    PEventBus.$on(OrganizationEventTypes.ORGANIZATION_INVITATION_SEND_SUCCESSFULLY, () => this.reloadOrganizations())
    PEventBus.$on(OrganizationEventTypes.ORGANIZATION_CREATED, () => this.reloadOrganizations())
  },
  beforeDestroy() {
    PEventBus.$off(OrganizationEventTypes.ORGANIZATION_INVITATION_SEND_SUCCESSFULLY, () => this.reloadOrganizations())
    PEventBus.$off(OrganizationEventTypes.ORGANIZATION_CREATED, () => this.reloadOrganizations())
  },
  setup() {
    const {
      fetchMyWorkspaces,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,

    } = useWorkspacesList()

    return {
      fetchMyWorkspaces,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrganizationListTable,
      refetchData,

      // Filter
      avatarText,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>


<style lang="scss" scoped>
.add-new-card {
  text-align: center;
  position: absolute;
  color: white;
  background-color: #03A5AA;
  border-radius: 5px;
  padding: 4px;
  width: 70%;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.card-body {

}

.workspace-create-card {
  width: 100%;
  padding: 0;
  height: 100%;
  background-image: url('../../../assets/images/illustration/workspace_new_placeholder.svg');
  background-size: cover;
  background-position-y: -20%;
  background-repeat: no-repeat;
  opacity: 0.9;
  filter: grayscale(50%) blur(1px);
  transition: filter 2s ease;
}

.workspace-card {
  height: 85%;

  &:hover {
    transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
    box-shadow: 0 0 1px 1px #03A5AA, 0 0 1px 1px #03A5AA;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
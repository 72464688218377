<template>
  <div class="loading-container justify-content-center align-content-center text-center align-items-center">
    <div class="row">
      <div v-if="withLogo" class="col">
        <img  :src="require('@/assets/images/logo/papirai-logo.svg')" height="60"/>
      </div>

    </div>
    <div class="row">
      <div class="col mt-2">
        <b-spinner
            style="width: 2rem; height: 2rem;"
            variant="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {BSpinner} from 'bootstrap-vue'

export default {
  components: {BSpinner},
  props: {
    withLogo: {type:Boolean, default: true},
  },
  name: "Loading",
}
</script>

<style scoped>

.loading-container {
  margin-top: 20vh;
}

</style>

import {computed, ref, watch} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkspacesList() {
  // Use toast
  const toast = useToast()

  const refOrganizationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'name', sortable: true},
    {key: 'code', sortable: true},
    {key: 'role', sortable: true},
    {key: 'action'},
  ]

  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrganizationListTable.value ? refOrganizationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refOrganizationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchMyWorkspaces = (ctx, callback) => {
    store
      .dispatch('workspaces/fetchOrganizations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const {myWorkspaces, invitedWorkspaces, total} = response
        callback(myWorkspaces)
        totalOrganizations.value = total
      })
      .catch(() => {

      })
  }

  return {
    fetchMyWorkspaces,
    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrganizationListTable,

    // resolveUserRoleVariant,
    // resolveUserRoleIcon,
    // resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}

<template>
  <validation-observer
      ref="newOrganizationForm"
      #default="{invalid}">
    <b-form @submit.prevent>
      <b-row align-h="start">
        <b-col class="mb-1">
          <h5>{{ $t('organization.creationHeader')}}</h5>
        </b-col>
        <b-col cols="12">
          <b-form-group
              :label="$t('organization.name')"
              label-for="fh-doc-name">
            <validation-provider
                #default="{ errors }"
                name="Title"
                vid="title"
                rules="required">
              <b-form-input
                  v-model="name"
                  id="fh-doc-name"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('organization.name')"/>
              <div class="flex-wrap mt-50">
                <div><small>{{ $t('organization.creationDescription')}}</small></div>
                <div><small v-if="errors.length > 0" class="text-danger">
                  {{ $t('required') }}
                </small></div>
              </div>
            </validation-provider>
          </b-form-group>
          <b-form-group
              class="mt-2"
              :label="$t('organization.description')"
              label-for="fh-doc-description">
            <validation-provider
                #default="{ errors }"
                name="Desc"
                vid="description">
              <b-form-textarea
                  rows="5"
                  v-model="description"
                  id="fh-doc-description"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('organization.description')"/>
              <div class="flex-wrap mt-50">
                <div><small>{{ $t('organization.creationDetail')}}</small></div>
                <div><small v-if="errors.length > 0" class="text-danger">
                  {{ $t('required') }}
                </small></div>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <br/>
          <b-row align-h="start" class="px-1">
            <b-button
                @click="save"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="mr-1"
                :disabled="invalid"
                variant="primary">
              {{ $t('buttons.save') }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";

export default {
  name: "index",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    enableSkip: {type: Boolean, default: false},
    placeholderName: String
  },
  data() {
    return {
      users: [],
      name: null,
      description: null,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    validation() {
      return true
    },
    validationOccupation() {
      return true
    },
  },
  mounted() {
    this.name = this.placeholderName
  },
  methods: {
    save() {
      this.$refs.newOrganizationForm.validate()
          .then(success => {
            if (success) {
              const payload = {
                request: {
                  name: this.name,
                  description: this.description
                }
              }
              this.$store.dispatch('workspaces/create', payload)
                  .then(value => {
                    this.showSuccessToast()
                  })
                  .then(() => this.$emit('skip'))
                  .catch(err => {
                    this.showErrorToast()
                  })
            }
          })
    },
    showSuccessToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.created'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      }, {position: 'top-center'})
    },
    showErrorToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('messages.workspace.failedOnCreate'),
          icon: 'AlertCircleIcon',
          variant: 'warning',
        },
      }, {position: 'top-center'})
    },
  }
}
</script>

<style scoped>

</style>